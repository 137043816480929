import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  Contact,
  DonationMeta,
  ENVIRONMENT,
  Environment,
  Lead,
  PageActivity,
} from '@domains';
import { AppService } from '@donor/app.service';
import { OptimizelyService } from '@donor/optimizely.service';
import { FlowService } from '@donor/screens/flow/donor-flow.service';
import { BaseFlowStepComponent } from '@donor/screens/flow/steps/base-flow-step.component';
import {
  ConfirmDialogComponent,
  DesignService,
  ResponsiveService,
} from '@rspl-ui';

const NAME_REGEX = /^[^\s]+.*$/;
const EMAIL_REGEX =
  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const PHONE_REGEX = /^([0-9]{10})$/;

@Component({
  selector: 'app-flow-additional-information',
  templateUrl: './flow-additional-information.component.html',
  styleUrls: ['./flow-additional-information.component.scss'],
})
export class FlowAdditionalInformationComponent
  extends BaseFlowStepComponent
  implements OnInit, AfterViewInit
{
  public form!: FormGroup<{
    donor: FormGroup<{
      name: FormControl<string>;
      email: FormControl<string>;
      phone: FormControl<string>;
    }>;
    address?: FormGroup<{
      state: FormControl<string>;
    }>;
    instructions: FormControl<string | null>;
    donationItems: FormControl<string | null>;
  }>;
  @ViewChild('nameInput') nameInput!: ElementRef<HTMLInputElement>;

  smsApproval = true;

  constructor(
    override flowService: FlowService,
    override appService: AppService,
    override router: Router,
    override route: ActivatedRoute,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    protected dialog: MatDialog,
    protected override optimizelyService: OptimizelyService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      flowService,
      appService,
      router,
      route,
      designService,
      responsiveService,
      optimizelyService,
      environment
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.smsApproval =
      this.flowService.lead?.meta?.smsApproval === false ? false : true;
    this.form = new FormGroup({
      donor: new FormGroup({
        name: new FormControl(this.flowService.lead.donor?.name || '', [
          Validators.required,
          Validators.pattern(NAME_REGEX),
        ]),
        email: new FormControl(this.flowService.lead.donor?.email || '', [
          Validators.required,
          Validators.pattern(EMAIL_REGEX),
        ]),
        phone: new FormControl(this.flowService.lead.donor?.phone || '', [
          Validators.required,
          Validators.pattern(PHONE_REGEX),
        ]),
      }),
      instructions: new FormControl(this.flowService.lead.instructions),
      donationItems: new FormControl(this.flowService.lead.donationItems),
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.nameInput?.nativeElement.focus();
    });
  }

  static override isCompleted(lead: Lead) {
    return (
      !!lead.donor.email &&
      !!lead.donor.name &&
      !!lead.donor.phone &&
      NAME_REGEX.test(lead.donor.name) &&
      EMAIL_REGEX.test(lead.donor.email) &&
      PHONE_REGEX.test(lead.donor.phone)
    );
  }

  override isValid(): boolean {
    return !this.form.invalid;
  }

  override getFromValue(): Partial<Lead> {
    this.appService
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.SMS_CHECKBOX,
        this.smsApproval ? 'checked' : 'unchecked'
      )
      .subscribe();
    return {
      donor: new Contact(this.form.get('donor').getRawValue()),
      instructions: this.form.get('instructions').value,
      donationItems: this.form.get('donationItems').value,
      meta: new DonationMeta({
        ...this.flowService.lead.meta,
        smsApproval: this.smsApproval,
      }),
    };
  }

  override showError(): void {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
  }

  override saveButtonActivity(): ButtonActivity {
    return ButtonActivity.DONOR_INFO_SUBMIT;
  }

  override pageVisitActivity(): PageActivity {
    return PageActivity.DONOR_INFO_PAGE;
  }

  smsDialog(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      maxWidth: '100vw',
      data: {
        message: `By providing your phone number, you expressly agree to authorize Resupply Inc to contact you by text, or phone at the home or mobile phone number provided to contact you in order to schedule your donation pickup and or to manage the details of your pickup. <br/>You may opt-out to specific updates at any time by replying STOP to any message received. Messaging and data rates may apply.`,
        hideNo: true,
        yes: 'Close',
        color: 'primary',
      },
    });
  }
}
